import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { AUTH_MODULE } from "@/core/config/role.config";
import { RbacService } from "@/core/services/rbac.service";

export default {
  components: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Integration Office", route: "navigation-menu" }
    ]);
  },
  computed: {
    allowedNavigation() {
      return this.navigation.where(x => this.allowedLinks(x.links).length > 0);
    }
  },
  methods: {
    allowedLinks(links) {
      const allowedLinks = [];
      links.forEach(link => {
        if (RbacService.isAllowed(link.roles)) allowedLinks.push(link);
      });
      return allowedLinks;
    }
  },
  data: () => ({
    navigation: [
      {
        header: "Command Base",
        links: [
          {
            name: "Accounts",
            to: "command-base-account",
            icon: "mdi-shield-account",
            roles: AUTH_MODULE.IO_ACCOUNTS
          },
          {
            name: "Contacts",
            to: "command-base-contact",
            icon: "mdi-card-account-phone",
            roles: AUTH_MODULE.IO_CONTACTS
          }
        ],
        flexmd: 4,
        flexsm: 6
      }
    ]
  })
};
